@import url('https://use.typekit.net/etg0rno.css');

body {
  display: block;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'myriad-pro', sans-serif;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body:after,
body:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.sidebar-item svg,
.sidebar-svg-icon {
  color: #B6000F !important;
}

.sidebar-item[data-selected='true'] svg,
.sidebar-item[data-selected='true'] .sidebar-svg-icon {
  color: #ffffff !important;
}

#storybook-explorer-tree .sidebar-subheading {
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.04286em !important; 
  color: #303030;
}

#storybook-explorer-tree .sidebar-item {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.02857em !important;
}

#storybook-explorer-tree .sidebar-item[data-selected='true'] {
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.02857em !important;
}

#storybook-docs .sbdocs-content {
  font-family: 'myriad-pro', sans-serif;
  max-width: 1200px;
}

#storybook-docs h1.sbdocs-title {
  font-size: 44px;
  line-height: 60px;
  color: #303030;
  margin-top: 49px;
}

#storybook-docs details {
  position: relative;
  z-index: 99;
}

#storybook-docs .sbdocs-p {
  font-family: 'myriad-pro', sans-serif;
  font-size: 18px;
  line-height: 27px;
  color: #303030;
  margin-top: 24px;
}